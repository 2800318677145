import React, { Component }  from 'react';
import axios from 'axios';

import { Section, Container } from '@components/global';
import { Formik } from 'formik';
import theme from '../../styles/theme';

const contactLambdaUrl = "https://3v7tykfhb5.execute-api.us-east-1.amazonaws.com/v1/contact"
export default class Contact extends Component {

  state = {
    contacted: false
  }

  renderForm() {
    return (
      <Formik
      initialValues={{ name: '', email: '', message: '' }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        this.setState({contacted: true})
        const response = await axios.post(
          contactLambdaUrl,
          values,
          {
            headers: {'Content-Type': 'application/json'}
          }
        )
        setSubmitting(true);
      }}
      >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} id="contact">
          <div className="input-wrap">
            <input
              type="name"
              name="name"
              placeholder="Your Name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
          </div>

          <div className="input-wrap">
            <input
              type="email"
              name="email"
              placeholder="Email (jane.doe@gmail.com)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <span style={style.formMsg}>{errors.email && touched.email && errors.email}</span>
          </div>
          <div className="input-wrap">
            <textarea
              name="message"
              placeholder="How can we help you?"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
            ></textarea>
            <span style={style.formMsg}>{errors.message && touched.message && errors.message}</span>
          </div>
          <button type="submit" disabled={isSubmitting} style={style.submit}>
            Submit
          </button>
        </form>
      )}
      </Formik>      
    )  
  }

  render() {

    let contactForm = this.state.contacted ? <h3 style={style.title} >Thank you. One of our represenatives will be with you shortly.</h3> : this.renderForm()
    return (
      <Section id="contact" style={style.section}>
      <Container>
        <h1 style={style.title}>Contact Us</h1>
        <p>Fill out your name, email, and a little bit about what you're looking for and we'll contact you within 24 hours.</p>
        <div id="formWrap" style={style.formWrap}>
        {contactForm}
        </div>
      </Container>
    </Section>
    )
  }
};


const style = {
  section: {
    background: theme.color.style1.green
  },
  formWrap: {
    marginTop: '40px',
    minHeight: '430px'
  },
  title: {
    color: theme.color.white.regular,
    marginBottom: 40
  },
  formMsg: {
    display: 'block',
    padding: '15px 14px',
  },
  submit: {
    backgroundColor: theme.color.white.regular,
    padding: '15px 25px',
    borderRadius: '10px',
    fontSize: '1.2em',
  }
}
